/*@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');*/

body {
  background: #2c2e3e;
  /*background: #fcf9e9;*/
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}

@media (min-width: 992px) {
  .navbar.fixed-top {
    box-shadow: 0 1px 15px 1px rgba(69,65,78,.1);
    background: #fff;
    padding: 0;
  }
  #mainNav .navbar-brand {
    width: 250px;
    background: #b9372f;
    color: #fff;
    padding: 13px 25px;
  }
}

ol.breadcrumb {
  padding: 10px 0 10px;
  background: none;
}

/*.dropdown-menu*/
.dropdown-menu {
  border-radius: 0;
}




/*Data Table*/
.dt-bootstrap4 {
  padding: 0;
}
.dt-bootstrap4 table {
  font-size: 14px;
}

.modal-content {
  border-radius: 0px;
}
.btn {
  border-radius: 0;
}

.bg-warning {
  background-color: #ffb822 !important;
}
.bg-danger {
    background-color: #f4516c !important;
}

.bg-primary {
    background-color: #36a3f7 !important;
}

